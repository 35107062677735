import React from 'react';
import styled from 'styled-components';

// components
import Layout from '../components/layout';
import Seo from '../components/seo';

// styled-comonents
const NotFoundContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 4rem 0;
`;

const NotFoundPage = React.memo(() => (
  <Layout>
    <Seo title="404: Pagina no encontrada" />
    <NotFoundContainer>
      <h1>Lo sentimos, esta pagina no fue encontrada.</h1>
      <p>La página que está buscando no existe, ya no existe o ha sido movida.</p>
    </NotFoundContainer>
  </Layout>
));

export default NotFoundPage;
